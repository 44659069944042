.pagination-controls {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-left: auto;
    margin-right: auto;

    .page-number-buttons {
        display: flex;
        overflow-x: scroll;
        width: 60vw;
    }

 }

 .tickets-table {
    display: flex;
    flex-direction: column;
 }
 